import { adjust, assoc, compose, evolve, has, insert, insertAll, lensPath, over, remove, view } from 'ramda';

import { showErrorSnackbar } from 'hooks/useSnackbar';
import { assignOriginalIdToBlocks } from 'utils/blocksHelpers';

import * as types from './types';

export const addBlocksInsidePage = <T extends types.CommonBlock>({
  pageIndex,
  blockIndex,
  blockOrBlocks,
  data,
}: types.AddBlocksInsidePageProps<T>) => {
  const { formattedBlockIndex: pathToBlock, index = 0 } = getBlockIndexes(blockIndex);
  if (typeof index === 'string') {
    showErrorSnackbar('Error in pasting block. Please try again or refresh the page');
    console.error('Index type mistmatch inside addBlocksInsidePage handler');
    return;
  }

  const newBlocksWithFakeIds = assignOriginalIdToBlocks(Array.isArray(blockOrBlocks) ? blockOrBlocks : [blockOrBlocks]);

  const insertBlocks = (newBlocks: T[]) => (currentBlocks: T[]) =>
    over(lensPath(pathToBlock), (childrenOfBlock) => insertAll(index + 1, newBlocks, childrenOfBlock), currentBlocks);

  // @ts-expect-error
  const updatedPages = adjust(pageIndex, evolve({ blocks: insertBlocks(newBlocksWithFakeIds) }), data?.pages);

  return updatedPages;
};

export const editBlockInsidePage = ({ pageIndex, blockIndex, data, newBlock }: types.EditBlockInsidePageProps) => {
  const { formattedBlockIndex, index = 0 } = getBlockIndexes(blockIndex);
  if (typeof index === 'string') {
    showErrorSnackbar('Error in pasting block. Please try again or refresh the page');
    console.error('Index type mistmatch inside addBlocksInsidePage handler');
    return;
  }

  const handleBlocks = over<types.CommonBlock, types.CommonBlock[]>(
    lensPath(formattedBlockIndex),
    compose(remove<types.CommonBlock>(index, 1), insert<types.CommonBlock>(index + 1, newBlock)),
  );

  // @ts-expect-error
  const updatedPages = adjust(pageIndex, evolve({ blocks: handleBlocks }), data?.pages);

  return updatedPages;
};

export const removeBlockInsidePage = <T extends types.CommonBlock>({
  pageIndex,
  blockIndex,
  data,
}: types.RemoveBlockInsidePageProps<T>) => {
  const { formattedBlockIndex, index = 0 } = getBlockIndexes(blockIndex);
  if (typeof index === 'string') {
    showErrorSnackbar('Error in pasting block. Please try again or refresh the page');
    console.error('Index type mistmatch inside addBlocksInsidePage handler');
    return;
  }

  const currentBlocks = data?.pages?.[pageIndex]?.blocks;
  if (!currentBlocks) return;

  const currentBlock = view<types.OptionBlock[], types.OptionBlock>(
    lensPath([...formattedBlockIndex, index]),
    currentBlocks,
  );

  const destroyIfHasIdOrRemove = has('id', currentBlock)
    ? adjust<types.OptionBlock>(index, assoc('_destroy', true))
    : remove<types.OptionBlock>(index, 1);

  const handleBlocks =
    formattedBlockIndex.length > 0
      ? over<types.OptionBlock, types.OptionBlock[]>(lensPath(formattedBlockIndex), destroyIfHasIdOrRemove)
      : destroyIfHasIdOrRemove;

  // @ts-expect-error
  const updatedPages = adjust(pageIndex, evolve({ blocks: handleBlocks }), data?.pages);

  return { updatedPages, currentBlock };
};

export function getBlockIndexes(blockIndex: number[]) {
  const formattedBlockIndex = blockIndex
    .join(',children,')
    .split(',')
    .map((value) => {
      return isNaN(Number(value)) ? value : Number(value);
    });
  const index = formattedBlockIndex.pop();

  return { formattedBlockIndex, index };
}
