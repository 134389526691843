import { insertAll, lensPath, remove, set, update, view } from 'ramda';

import { IAccordionSpoiler, TBlock } from 'detailsv2/__blocks';
import { assignOriginalIdToBlocks } from 'utils/blocksHelpers';
import { isDefined, isNotDefined } from 'utils/ramda';

import * as t from './types';

const getBlock = (props: t.GetBlockProps) => {
  const { blockIndex, pageIndex, data } = props;
  const blockPath = Array.isArray(blockIndex)
    ? blockIndex.flatMap((item, i) => (i === 0 ? item : ['children', item]))
    : [blockIndex];
  const blockLens = lensPath<t.AddBlockInsideAccordionProps['data']['pages'], TBlock>([
    pageIndex,
    'blocks',
    ...blockPath,
  ]);
  const block = view(blockLens, data.pages);

  return { block, blockLens };
};

const getSpoiler = ({ block, spoilerIndex }: t.GetSpoilerProps) => {
  const spoilerPath = ['data', 'spoilers', spoilerIndex];
  const spoilerLens = lensPath<TBlock, IAccordionSpoiler>(spoilerPath);
  const spoilerData = view(spoilerLens, block);
  const isSpoilerChildrenExist = isDefined(spoilerData?.children);

  const spoilerChildrenLens = lensPath<TBlock, IAccordionSpoiler['children']>([...spoilerPath, 'children']);
  const spoilerChildrenData = view(spoilerChildrenLens, block)!; // checked upper in hasPath

  return { spoilerLens, spoilerData, isSpoilerChildrenExist, spoilerChildrenLens, spoilerChildrenData };
};

export const addBlockInsideAccordionHandler = (
  props: t.AddBlockInsideAccordionProps,
): t.AddBlockInsideAccordionProps['data']['pages'] | undefined => {
  const { data, blockOrBlocks, spoilerIndex, spoilerBlockIndex, insertPosition = 'after' } = props;
  const newBlocksWithFakeIds = assignOriginalIdToBlocks(Array.isArray(blockOrBlocks) ? blockOrBlocks : [blockOrBlocks]);

  const { block, blockLens } = getBlock(props);
  if (isNotDefined(block)) return;
  if (!block) return; // ts check

  const { spoilerData, spoilerLens, isSpoilerChildrenExist } = getSpoiler({ block, spoilerIndex });
  if (!spoilerData) return;

  let updatedSpoiler;

  if (!isSpoilerChildrenExist) {
    updatedSpoiler = {
      ...spoilerData,
      children: newBlocksWithFakeIds,
    };
  } else {
    const insertShift = insertPosition === 'before' ? 0 : 1;
    const updatedSpoilerChildren = insertAll(
      spoilerBlockIndex + insertShift,
      newBlocksWithFakeIds,
      spoilerData?.children!,
    );

    updatedSpoiler = {
      ...spoilerData,
      children: updatedSpoilerChildren,
    };
  }

  const updatedBlock = set(spoilerLens, updatedSpoiler, block);
  const updatedPages = set(blockLens, updatedBlock, data.pages);

  return updatedPages;
};

export const editBlockInsideAccordionHandler = (
  props: t.EditBlockInsideAccordionProps,
): t.EditBlockInsideAccordionProps['data']['pages'] | undefined => {
  const { data, spoilerIndex, spoilerBlockIndex, newObject } = props;

  const { block, blockLens } = getBlock(props);
  if (isNotDefined(block)) return;
  if (!block) return; // ts check

  const { spoilerData, spoilerChildrenData, spoilerChildrenLens } = getSpoiler({ block, spoilerIndex });
  if (!spoilerData) return;
  if (!spoilerChildrenData) return;

  const updatedSpoilerChildren = update(spoilerBlockIndex, newObject, spoilerChildrenData);

  const updatedBlock = set(spoilerChildrenLens, updatedSpoilerChildren, block);
  const updatedPages = set(blockLens, updatedBlock, data.pages);

  return updatedPages;
};

export const removeBlockInsideAccordionHandler = (
  props: t.RemoveBlockInsideAccordionProps,
): { updatedPages: t.RemoveBlockInsideAccordionProps['data']['pages'] | undefined; showAlert: boolean } | undefined => {
  const { data, spoilerIndex, spoilerBlockIndex } = props;

  const { block, blockLens } = getBlock(props);
  if (isNotDefined(block)) return;
  if (!block) return; // ts check

  const { spoilerData, spoilerChildrenData, spoilerChildrenLens } = getSpoiler({ block, spoilerIndex });
  if (!spoilerData) return;
  if (!spoilerChildrenData) return;

  const deletedBlock = spoilerChildrenData[spoilerBlockIndex];
  const updatedSpoilerChildren = remove(spoilerBlockIndex, 1, spoilerChildrenData);

  const updatedBlock = set(spoilerChildrenLens, updatedSpoilerChildren, block);
  const updatedPages = set(blockLens, updatedBlock, data.pages);

  const showAlert =
    deletedBlock?.type === 'image' || deletedBlock?.type === 'embed' || deletedBlock?.type === 'document';

  return { updatedPages, showAlert };
};
