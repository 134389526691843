import { useCallback } from 'react';

import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import { patchShareReport } from 'api/shareReport';
import { getDateString } from 'components/Pages/Admin/GuestPortal/Flights/AddManually/utils';
import store from 'configureStore';
import { LANDING_PAGE_TYPES, LIBRARY_CONTENT } from 'constants/index';
import { getPath, PAGE_DETAILS_PATH } from 'constants/routes';
import { EDITING_PERMISSION_BLOCK, EDITING_PERMISSION_TEXT } from 'constants/texts';
import { showDefaultSnackbar, showErrorSnackbar } from 'hooks/useSnackbar';
import i18n from 'i18n';
// eslint-disable-next-line import/named
import { all, groupBy, isEmpty, isNil, map, pipe, sortBy } from 'ramda';
import { saveSampleItineraryThunk, saveSampleLookbookThunk } from 'store/admin/itineraries/thunks';
import { setPolicyShareReportToFalseAction } from 'store/itinerariesItem';
import { itinerariesItemDataSelector } from 'store/itinerariesItem/selectors';
import {
  createItinerariesItemThunk,
  publishSampleItinerariesItemThunk,
  publishSampleLookbookItemThunk,
  updateItinerariesItemThunk,
} from 'store/itinerariesItem/thunks';
import { capitalize } from 'utils/common';
import { cleanUpArrayFields } from 'utils/form';
import { useItinerariesItemContext } from 'utils/itineraries/context';
import { useItinerariesItemStepPath } from 'utils/itineraries/functions/useItinerariesItemStepPath';
import logger from 'utils/logger';
import { getInOr } from 'utils/ramda';

import { ITINERARIES_ITEM_TYPE_TO_LABEL_MAP } from '../map';
import GeneralSaveError from './GeneralSaveError';

import { getLangingPageType } from './index';

const ErrorText = ({ pageId, accountId, isInvalidVideoUrl, guestFormError }) => {
  const handleLinkClick = useCallback(() => {
    window.open(getPath(PAGE_DETAILS_PATH, { pageId, accountId, libType: LIBRARY_CONTENT }));
  }, [accountId, pageId]);

  if (isInvalidVideoUrl) {
    return <>Please enter a valid video link or delete video input to save your Itinerary</>;
  }

  if (guestFormError) {
    return (
      <>Oops! It seems this entity has been changed during another session. Please refresh your page to continue</>
    );
  }

  return (
    <>
      Oops... seems that one Property Page at the table has no link to Master Page. Please{' '}
      <div
        onClick={handleLinkClick}
        style={{ fontWeight: 'bold', textDecoration: 'underline', display: 'inline', cursor: 'pointer' }}
      >
        reattach a new page here
      </div>
    </>
  );
};

const INVALID_VIDEO_URL = '3 Invalid video URL';
const MASTER_PROPERTY_PAGE_NOT_FOUND = 'master_property_id must be master property page';
const GUEST_NOT_FIND = "Couldn't find GuestPortal::Guest with";
const REMOVED_PROPERTY = "doesn't exist anymore";

function getErrorsMap(source = {}) {
  return Object.keys(source).reduce((res, key) => {
    res[key] = source[key][0];

    return res;
  }, {});
}

const handleShowValidationErrors = (errors = {}, { onlyFirst = false } = {}) => {
  const keys = Object.keys(errors.source || {});

  if (onlyFirst) {
    if (isEmpty(keys)) return;
    const message = errors.source[keys[0]]?.[0];
    if (!message) return;

    showErrorSnackbar(capitalize(message));
    return;
  }

  Object.keys(errors?.source || {}).forEach((key) => {
    const message = errors.source[key]?.[0];
    if (!message) return;

    showErrorSnackbar(capitalize(message));
  });
};

const useItinerariesItemNextStepHandler = (params = {}) => {
  const { onBeforeNext, onAfterNext, onNextError, showValidationError = false, showValidationErrors = false } = params;
  const dispatch = useDispatch();
  const { currentStep, nextStep, isCreating, itemId, itemType, withMultipleUsersWarning } = useItinerariesItemContext();
  const history = useHistory();
  const getStepPath = useItinerariesItemStepPath(itemType);
  const id = useParams();

  const handleNext = async (rawData) => {
    try {
      const data = { ...cleanUpArrayFields(rawData), currentStep };
      if (onBeforeNext) onBeforeNext(data);
      const savedItem = isCreating
        ? await dispatch(createItinerariesItemThunk(data))
        : await dispatch(updateItinerariesItemThunk(itemId, data));
      if (onAfterNext) onAfterNext(savedItem);
      history.push(getStepPath(nextStep, savedItem.id));
    } catch (error) {
      if (onNextError) onNextError(error);

      if (showValidationError && error?.errors?.status === 422) {
        handleShowValidationErrors(error.errors, { onlyFirst: true });
      } else if (showValidationErrors && error?.errors?.status === 422) {
        handleShowValidationErrors(error.errors);
      } else if (error?.errors?.title?.includes(REMOVED_PROPERTY)) {
        showErrorSnackbar(error.errors.title);
      } else if (error?.errors?.title?.includes(EDITING_PERMISSION_TEXT)) {
        showErrorSnackbar(EDITING_PERMISSION_BLOCK);
      } else if (error?.errors?.title?.includes(GUEST_NOT_FIND)) {
        showErrorSnackbar(<ErrorText guestFormError />);
      } else if (error?.errors?.description?.find((el) => el === MASTER_PROPERTY_PAGE_NOT_FOUND)) {
        const pageId = error?.errors?.source?.masterPropertyId?.[0]?.message;
        showErrorSnackbar(<ErrorText pageId={pageId} accountId={id} />);
      } else {
        showGeneralSaveErrorContent(`${ITINERARIES_ITEM_TYPE_TO_LABEL_MAP[itemType]} couldn't be saved.`, error);
      }

      throw error;
    }
  };

  return withMultipleUsersWarning(handleNext);
};

export const useItinerariesItemNextStepFormHandler = (params = {}) => {
  const handleNextStep = useItinerariesItemNextStepHandler(params);

  return async (values) => {
    try {
      await handleNextStep(cleanUpArrayFields(values));
    } catch (error) {
      const { errors } = error;
      logger.error(error);
      return getErrorsMap(errors?.source);
    }
  };
};

export const useItinerariesItemSaveHandler = (params = {}) => {
  const {
    onPrepareSaveData,
    onBeforeSave,
    onAfterSave,
    onSaveError,
    showSuccessSnackbar = true,
    showValidationError = false,
    showValidationErrors = false,
  } = params;
  const dispatch = useDispatch();
  const history = useHistory();
  const { currentStep, isCreating, itemId, itemType, withMultipleUsersWarning } = useItinerariesItemContext();
  const getStepPath = useItinerariesItemStepPath(itemType);
  const id = useParams();

  const handleSave = async (rawData) => {
    try {
      let data = { ...cleanUpArrayFields(rawData), currentStep };
      if (onPrepareSaveData) data = onPrepareSaveData(data);

      if (onBeforeSave) onBeforeSave(data);
      const savedItem = isCreating
        ? await dispatch(createItinerariesItemThunk(data))
        : await dispatch(updateItinerariesItemThunk(itemId, data));
      if (onAfterSave) onAfterSave(savedItem);
      if (showSuccessSnackbar)
        showDefaultSnackbar(`${ITINERARIES_ITEM_TYPE_TO_LABEL_MAP[itemType]} '${savedItem.title}' has been saved.`);

      if (isCreating) {
        history.replace(getStepPath(currentStep, savedItem.id));
      }
    } catch (error) {
      if (onSaveError) onSaveError(error);

      if (showValidationError && error?.errors?.status === 422) {
        handleShowValidationErrors(error.errors, { onlyFirst: true });
      } else if (showValidationErrors && error?.errors?.status === 422) {
        handleShowValidationErrors(error.errors);
      } else if (error?.errors?.title?.includes(REMOVED_PROPERTY)) {
        showErrorSnackbar(error.errors.title);
      } else if (error?.errors?.title?.includes(GUEST_NOT_FIND)) {
        showErrorSnackbar(<ErrorText guestFormError />);
      } else if (error?.errors?.title?.includes(EDITING_PERMISSION_TEXT)) {
        showErrorSnackbar(EDITING_PERMISSION_BLOCK);
      } else if (error?.errors?.description?.find((el) => el === INVALID_VIDEO_URL)) {
        showErrorSnackbar(<ErrorText isInvalidVideoUrl />);
      } else if (error?.errors?.description?.find((el) => el === MASTER_PROPERTY_PAGE_NOT_FOUND)) {
        const pageId = error?.errors?.source?.masterPropertyId?.[0]?.message;
        showErrorSnackbar(<ErrorText pageId={pageId} accountId={id} />);
      } else {
        showGeneralSaveErrorContent(`${ITINERARIES_ITEM_TYPE_TO_LABEL_MAP[itemType]} couldn't be saved.`, error);
      }

      throw error;
    }
  };

  return withMultipleUsersWarning(handleSave);
};

export const useSaveSampleItemSaveHandler = () => {
  const dispatch = useDispatch();

  const handleSaveSample = async (id) => {
    try {
      const savedSample = await dispatch(saveSampleItineraryThunk(id));
      showDefaultSnackbar(`'${savedSample.title}' has been saved to your Content Library.`);
    } catch (error) {
      showGeneralSaveErrorContent("Sample couldn't be saved.", error);

      throw error;
    }
  };

  return handleSaveSample;
};

export const useSaveSampleLookbookSaveHandler = () => {
  const dispatch = useDispatch();

  const handleSaveSample = async (id) => {
    try {
      const savedSample = await dispatch(saveSampleLookbookThunk(id));
      showDefaultSnackbar(`'${savedSample.title}' has been saved to your Content Library.`);
    } catch (error) {
      showGeneralSaveErrorContent("Sample couldn't be saved.", error);

      throw error;
    }
  };

  return handleSaveSample;
};

export const usePublishSampleItemSaveHandler = () => {
  const dispatch = useDispatch();

  const handlePublishSample = async (id) => {
    try {
      const publishedSample = await dispatch(publishSampleItinerariesItemThunk(id));
      showDefaultSnackbar(`'${publishedSample.title}' has been published`);
    } catch (error) {
      showErrorSnackbar("Sample couldn't be published. Please try again.");
      throw error;
    }
  };

  return handlePublishSample;
};

export const usePublishSampleLookbookSaveHandler = () => {
  const dispatch = useDispatch();

  const handlePublishSample = async (id) => {
    try {
      const publishedSample = await dispatch(publishSampleLookbookItemThunk(id));
      showDefaultSnackbar(`'${publishedSample.title}' has been published`);
    } catch (error) {
      showErrorSnackbar("Sample couldn't be published. Please try again.");
      throw error;
    }
  };

  return handlePublishSample;
};

function showGeneralSaveErrorContent(msg, error) {
  const proposal = itinerariesItemDataSelector(store.getState());
  const handleReport = async () => {
    await patchShareReport({ error });

    store.dispatch(setPolicyShareReportToFalseAction());
  };

  showErrorSnackbar(
    <GeneralSaveError withShareReportButton={proposal?.policies?.shareReport} onShareReport={handleReport}>
      {msg}
    </GeneralSaveError>,
    { fitContent: true },
  );
}

const removeAIBlocksFromAccordion = (block) => {
  if (!block.data?.spoilers) return block;

  const handeledSpoilers = block.data?.spoilers?.map((spoiler) => {
    if (!spoiler?.children) return spoiler;
    const handeledSpoilerBlocks = spoiler?.children?.filter((block) => block?.data?.initialEditor !== 'ai-prompt');

    return { ...spoiler, children: handeledSpoilerBlocks };
  });

  return { ...block, data: { ...block.data, spoilers: handeledSpoilers } };
};

const recursivelyRemoveAIBlocksFromPageBlocks = (blocks) => {
  return blocks.map((block) => {
    if (block?.type === 'accordion') {
      return removeAIBlocksFromAccordion(block);
    }

    if (block.data.initialEditor === 'ai-prompt') {
      if (!block.data.text) {
        return {
          ...block,
          _destroy: true,
        };
      }

      return { ...block, data: { raw: block.data.raw, text: block.data.text } };
    }

    if (block?.children) {
      return {
        ...block,
        children: recursivelyRemoveAIBlocksFromPageBlocks(block.children),
      };
    }

    return block;
  });
};

export const removeAIBlocksFromPage = (data) => {
  try {
    return {
      ...data,
      blocks: recursivelyRemoveAIBlocksFromPageBlocks(data.blocks),
    };
  } catch (e) {
    console.error(e);
    return data;
  }
};

export const removeAIBlocksFromProposal = (data) => {
  try {
    return {
      ...data,
      pages: data.pages.map((page) => removeAIBlocksFromPage(page)),
    };
  } catch (e) {
    console.error(e);
    return data;
  }
};

// new conceptual versions for saving proposals data
export function useProposalSaveHandler() {
  const dispatch = useDispatch();
  const { isCreating, itemId } = useItinerariesItemContext();

  return useCallback(
    (data) => {
      if (isCreating) {
        return dispatch(createItinerariesItemThunk(data));
      }

      return dispatch(updateItinerariesItemThunk(itemId, data));
    },
    [dispatch, isCreating, itemId],
  );
}

export function getPageName(page) {
  if (getLangingPageType(page) === LANDING_PAGE_TYPES.COVER_PAGE) {
    return i18n.t('proposal_content.cover_page');
  }

  return page?.headline;
}

export function isSchedulesEmpty(collection) {
  if (!collection || isEmpty(collection)) return true;

  const result = collection.filter((item) => {
    if (isNil(item?.type)) return false;

    const propIsEmpty = pipe(
      getInOr([], 'contentState.blocks'),
      all(({ text }) => isEmpty(text)),
    );

    return !(propIsEmpty(item?.accommodation) && propIsEmpty(item?.details));
  });

  return result?.length === 0;
}

export const validateTimeline = (tripEvents = [], scheduleType = 'dates', ignoredEvents = []) => {
  const groupByStartDate = (events) =>
    groupBy((event) => {
      if (scheduleType === 'dates') {
        return event.datesRange?.start?.slice(0, 10) || event.startDate?.slice(0, 10) || '';
      }

      return event.daysRange?.start || event.startDay || '';
    })(events);
  const sortEventsByPosition = (days) => map((events) => sortBy((event) => event.positions?.[0] || -1)(events))(days);
  const filterActivityMultiDayEvents = (days) =>
    map((events) =>
      events.filter((event) => {
        if (event.type === 'activity' && event.positions?.length > 1) return false;
        return true;
      }),
    )(days);

  const getEventStartTime = (event) => {
    const formatTime = (time) => {
      if (!time) return time;
      if (isNaN(new Date(time))) return time;
      return new Date(time).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
    };

    const maybeTime = (() => {
      switch (event.type) {
        case 'flight':
          return event.data?.departureTime;
        case 'transfer':
          return event.data?.departureTime;
        case 'accommodation':
          return event.data?.checkIn;
        case 'activity':
          return event.data?.time?.start;
      }
    })();

    if (!maybeTime) return null;

    return getDateString({ date: new Date(), time: formatTime(maybeTime) });
  };

  const findStartTimesForAllEvents = (days) =>
    map((events) =>
      events.map((event) => ({
        id: event.id,
        time: getEventStartTime(event),
      })),
    )(days);

  const filterEventWithoutTime = (days) => map((events) => events.filter((event) => event.time))(days);

  const isOrdered = (times) =>
    times.every((timeObj, i) => i === 0 || new Date(times[i - 1].time) <= new Date(timeObj.time));

  const leaveOnlyUnorderedDays = (days) =>
    Object.entries(days).reduce((acc, [day, events]) => {
      if (!isOrdered(events)) {
        acc[day] = events.map((event) => event.id);
      }
      return acc;
    }, {});

  const filterIgnoredEventsInDays = (days) => {
    return Object.entries(days).reduce((acc, [day, eventIds]) => {
      const notIgnoredEventIds = eventIds.filter((id) => !ignoredEvents.includes(id));

      if (notIgnoredEventIds.length > 0) {
        acc[day] = notIgnoredEventIds;
      }

      return acc;
    }, {});
  };

  const unorderedEvents = pipe(
    groupByStartDate,
    sortEventsByPosition,
    filterActivityMultiDayEvents,
    findStartTimesForAllEvents,
    filterEventWithoutTime,
    leaveOnlyUnorderedDays,
    filterIgnoredEventsInDays,
  )(tripEvents);

  const daysWithUnorderedEvents = Object.keys(unorderedEvents);

  return [daysWithUnorderedEvents, unorderedEvents];
};
