import { useSelector } from 'react-redux';
import { useLocalStorage } from 'usehooks-ts';

import { SNACKBAR_SKIN } from 'components/shared/Snackbar';
import { showSnackbar } from 'hooks/useSnackbar';
import { userSelector } from 'store/user/selectors';

export const useIgnorePrimaryImageWarning = () => {
  const currentUserId = useSelector(userSelector).id;

  const [ignoredWarningMap, setIgnoredWarningMap] = useLocalStorage<WarningsIgnoredMap>(IGNORED_WARNING_KEY, {});

  const checkIsWarningIgnored = (proposalId: string) => {
    try {
      if (!currentUserId) throw new Error('Current user id is not defined');
      const userIgnoredProposals = ignoredWarningMap[currentUserId] || [];

      if (!userIgnoredProposals.includes(proposalId)) return false;

      return true;
    } catch (e) {
      console.error(e);
      showSnackbar({
        uniqueId: 'checkIsWarningIgnored',
        text: 'Something went wrong with warning ignoring. Please try again.',
        type: SNACKBAR_SKIN.ERROR,
      });
      return false;
    }
  };

  const setToIgnoreWarning = (proposalId: string) => {
    try {
      if (!currentUserId) throw new Error('Current user id is not defined');

      const userIgnoredProposals = ignoredWarningMap[currentUserId] || [];
      userIgnoredProposals.push(proposalId);
      ignoredWarningMap[currentUserId] = userIgnoredProposals;
      setIgnoredWarningMap(ignoredWarningMap);
    } catch (e) {
      console.error(e);
      showSnackbar({
        uniqueId: 'ignoreWarning',
        text: 'Something went wrong with warning ignoring. Please try again.',
        type: SNACKBAR_SKIN.ERROR,
      });
    }
  };

  return [checkIsWarningIgnored, setToIgnoreWarning] as const;
};

const IGNORED_WARNING_KEY = 'IGNORED_PRIMARY_IMAGE_WARNING_KEY_V_1.0';

export type WarningsIgnoredMap = {
  [userId: string]: string[];
};
