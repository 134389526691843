import React, { InputHTMLAttributes } from 'react';

const defaultProps = {
  className: undefined,
  onChange: () => {},
};

const RadioButton = (props: InputHTMLAttributes<HTMLInputElement>) => {
  return <input {...props} type="radio" />;
};

RadioButton.defaultProps = defaultProps;

export default RadioButton;
