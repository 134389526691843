import * as R from 'ramda';
import { omit } from 'ramda';
import { matchPath } from 'react-router';

import { SNACKBAR_SKIN } from 'components/shared/Snackbar';
import { ITINERARIES_ITEM_STEP_PATH } from 'constants/routes';
import { showSnackbar } from 'hooks/useSnackbar';
import i18n, { loadTranslations } from 'i18n';
import * as handlers from 'store/shared/accordionBlockHandlers';
import { setAppHasChangesAction } from 'store/unsavedChanges';
import { confirmAlert } from 'utils/alert';
import axios from 'utils/axios';
import {
  isSample,
  ITINERARIES_ITEM_TYPE_TO_ROUTE_SEGMENT_MAP,
  mapPathSegment2ItemType,
} from 'utils/itineraries/functions';
import { trackEvent, EMixpanelEvents } from 'utils/mixpanel';

import { accountIdSelector } from '../user/selectors';
import { itinerariesItemLandingSelector, itinerariesItemDataSelector } from './selectors';
import * as utils from './utils';

import { resetItinerariesItemDataAction, setItinerariesItemDataAction, setLandingDataAction } from '.';

export const setLandingDataThunk =
  (data, params = {}) =>
  (dispatch) => {
    const { isLocalUpdate = false } = params;
    dispatch(setLandingDataAction(data));
    if (!isLocalUpdate) {
      dispatch(setAppHasChangesAction(true));
    }
  };

export const updateLandingContentThunk = (data) => async (dispatch) => {
  dispatch(setLandingDataThunk(data));
};

export const buildItinerariesItemRoute = (accId, id) => {
  // TODO: matchPath may return null
  const { params = {} } = matchPath(window.location.pathname, {
    path: ITINERARIES_ITEM_STEP_PATH,
  });
  const { itemType: itemTypePathSegment, libType } = params;

  if (!itemTypePathSegment) {
    throw new Error(`${buildItinerariesItemRoute.name} No itemTypePathSegment found: ${itemTypePathSegment}`);
  }

  const itinerariesItemType = mapPathSegment2ItemType(itemTypePathSegment);
  if (!itinerariesItemType) {
    throw new Error(`${buildItinerariesItemRoute.name} No itinerariesItemType found: ${itinerariesItemType}`);
  }

  if (isSample(libType)) {
    const type = itinerariesItemType === 'lookbook' ? 'sample_lookbooks' : 'sample_itineraries';
    return `/acc/${accId}/${type}` + (id ? `/${id}` : '');
  }

  return `/acc/${accId}/${ITINERARIES_ITEM_TYPE_TO_ROUTE_SEGMENT_MAP[itinerariesItemType]}` + (id ? `/${id}` : '');
};

export const getItinerariesItemThunk = (id, type) => async (_, getStore) => {
  const accountId = accountIdSelector(getStore());
  const data = await axios.get(`/acc/${accountId}/${type}/${id}`).then(({ data = {} }) => data);
  return data;
};

export const updateItinerariesItemThunk = (id, data) => async (dispatch, getStore) => {
  const accountId = accountIdSelector(getStore());
  const route = buildItinerariesItemRoute(accountId, id);
  trackEvent(EMixpanelEvents.UPDATE_ITINERARY, data);
  const response = await axios.patch(route, data);

  const { settings } = response.data;
  await loadTranslations(i18n, settings?.language);

  dispatch(setItinerariesItemDataAction(response.data));
  dispatch(setAppHasChangesAction(false));
  return response.data;
};

export const createItinerariesItemThunk = (data) => async (dispatch, getStore) => {
  const accountId = accountIdSelector(getStore());
  trackEvent(EMixpanelEvents.CREATE_ITINERARY, data);
  const response = await axios.post(buildItinerariesItemRoute(accountId), data);

  dispatch(setItinerariesItemDataAction(response.data));
  return response.data;
};

export const deleteItinerariesItemThunk = (id) => async (dispatch, getStore) => {
  const accountId = accountIdSelector(getStore());

  await axios.delete(buildItinerariesItemRoute(accountId, id));
  dispatch(resetItinerariesItemDataAction());
};

export const removeLandingBlockThunk =
  ({ pageIndex, blockIndex }) =>
  async (dispatch, getStore) => {
    if (!Array.isArray(blockIndex)) {
      blockIndex = [blockIndex];
    }

    const data = itinerariesItemLandingSelector(getStore());

    const { updatedPages, currentBlock } = utils.removeBlockInsidePage({ pageIndex, blockIndex, data });

    const showAlert =
      currentBlock?.type === 'image' ||
      currentBlock?.type === 'embed' ||
      currentBlock?.type === 'document' ||
      currentBlock?.type === 'accordion';

    if (showAlert) {
      confirmAlert({
        title: 'Are you sure you want to delete this element?',
        onConfirm: () => dispatch(updateLandingContentThunk({ pages: updatedPages })),
      });
    } else {
      dispatch(updateLandingContentThunk({ pages: updatedPages }));
    }
  };

export const addLandingBlockThunk =
  ({ pageIndex, blockIndex, newObject: blockOrBlocks }) =>
  async (dispatch, getStore) => {
    if (!Array.isArray(blockIndex)) {
      blockIndex = [blockIndex];
    }

    const data = itinerariesItemLandingSelector(getStore());
    const updatedPages = utils.addBlocksInsidePage({ pageIndex, blockIndex, blockOrBlocks, data });

    dispatch(updateLandingContentThunk({ pages: updatedPages }));
  };

export const editLandingBlockThunk =
  ({ pageIndex, blockIndex, newObject }) =>
  async (dispatch, getStore) => {
    if (!Array.isArray(blockIndex)) {
      blockIndex = [blockIndex];
    }

    const data = itinerariesItemLandingSelector(getStore());

    if (R.isNil(data?.pages)) {
      return;
    }

    const updatedPages = utils.editBlockInsidePage({ pageIndex, blockIndex, newBlock: newObject, data });

    dispatch(updateLandingContentThunk({ pages: updatedPages }));
  };

export const editLandingSectionTitleThunk =
  ({ pageIndex, title }) =>
  async (dispatch, getStore) => {
    const data = itinerariesItemLandingSelector(getStore());

    if (R.isNil(data?.pages)) {
      return;
    }

    const updateTitle = (data) => ({ ...data, title });
    const newData = R.adjust(pageIndex, updateTitle, data.pages);

    dispatch(updateLandingContentThunk({ pages: newData }));
  };

export const editLandingPageThunk =
  ({ pageIndex, newObject }) =>
  async (dispatch, getStore) => {
    const data = itinerariesItemLandingSelector(getStore());

    if (R.isNil(data?.pages)) {
      return;
    }

    const updatePage = (currentPage) => {
      const updatedPage = { ...currentPage, ...newObject };
      return updatedPage;
    };
    const newData = R.adjust(pageIndex, updatePage, data.pages);

    dispatch(updateLandingContentThunk({ pages: newData }));
  };

export const updateLandingPageHiddenValueThunk =
  ({ pageIndex, hidden }) =>
  async (dispatch, getStore) => {
    if (hidden) {
      showSnackbar({
        text: 'This page will be hidden on the client facing proposal.',
        type: SNACKBAR_SKIN.DEFAULT,
        time: 3 * 1000,
      });
    }

    const data = itinerariesItemLandingSelector(getStore());
    const updatePage = (currentPage) => {
      const updatedPage = { ...currentPage, hidden };
      return updatedPage;
    };
    const newData = R.adjust(pageIndex, updatePage, data?.pages);

    dispatch(updateLandingContentThunk({ pages: newData }));
  };

export const updateItineraryLandingPageStylesThunk =
  ({ customStyles }) =>
  async (dispatch, getStore) => {
    const data = itinerariesItemDataSelector(getStore());

    dispatch(setItinerariesItemDataAction({ ...data, customStyles }));
    dispatch(setAppHasChangesAction(true));
  };

export const setProposalLandingV2Config = (landingConfig) => async (dispatch, getStore) => {
  const data = itinerariesItemDataSelector(getStore());

  // Remove proposal settings from config before sending to BE
  const updatedLandingConfig = omit(['settings'], landingConfig);

  dispatch(setItinerariesItemDataAction({ ...data, landingConfig: updatedLandingConfig }));
  dispatch(setAppHasChangesAction(true));
};

export const updateProposalLandingSettings = (id, settings) => async (dispatch, getStore) => {
  const accountId = accountIdSelector(getStore());
  const setSettingsByDefault = settings.byDefault;

  delete settings.byDefault;

  // Update member settings
  if (setSettingsByDefault) {
    await axios.patch(`/acc/${accountId}/members/proposal_settings`, settings);
  }

  // Update proposal
  return dispatch(updateItinerariesItemThunk(id, settings));
};

export const updateItineraryLandingPageFontsThunk =
  ({ customFonts }) =>
  async (dispatch, getStore) => {
    const data = itinerariesItemDataSelector(getStore());

    dispatch(setItinerariesItemDataAction({ ...data, customFonts }));
    dispatch(setAppHasChangesAction(true));
  };

export const fetchSampleItinerariesItemThunk = (id, view) => async (dispatch, getStore) => {
  const accountId = accountIdSelector(getStore());
  const params = view ? `?view=${view}` : '';
  const response = await axios.get(`/acc/${accountId}/sample_itineraries/${id}${params}`);

  return response?.data;
};

export const publishSampleItinerariesItemThunk = (id) => async (dispatch, getStore) => {
  const accountId = accountIdSelector(getStore());
  const response = await axios.put(`/acc/${accountId}/sample_itineraries/${id}/approve`);

  dispatch(setItinerariesItemDataAction(response?.data));

  return response?.data;
};

export const fetchSampleLookBookItemThunk = (id, view) => async (dispatch, getStore) => {
  const accountId = accountIdSelector(getStore());
  const params = view ? `?view=${view}` : '';
  const response = await axios.get(`/acc/${accountId}/sample_lookbooks/${id}${params}`);

  return response?.data;
};

export const publishSampleLookbookItemThunk = (id) => async (dispatch, getStore) => {
  const accountId = accountIdSelector(getStore());
  const response = await axios.put(`/acc/${accountId}/sample_lookbooks/${id}/approve`);

  dispatch(setItinerariesItemDataAction(response?.data));

  return response?.data;
};

export const fetchGuestPortalThunk = (id) => async (dispatch, getStore) => {
  const accountId = accountIdSelector(getStore());
  const response = await axios.get(`/acc/${accountId}/guest_portals/${id}`);

  dispatch(setItinerariesItemDataAction(response.data));
  return response.data;
};

export const updateGuestPortalThunk = (id, data) => async (dispatch, getStore) => {
  const accountId = accountIdSelector(getStore());
  trackEvent(EMixpanelEvents.UPDATE_GUEST_PORTAL, data);
  const response = await axios.patch(`/acc/${accountId}/guest_portals/${id}`, data);
  return response.data;
};

export const addLandingBlockInsideAccordionThunk =
  ({ pageIndex, blockIndex, newObject: blockOrBlocks, spoilerIndex, spoilerBlockIndex, insertPosition }) =>
  async (dispatch, getStore) => {
    const data = itinerariesItemLandingSelector(getStore());
    const updatedPages = handlers.addBlockInsideAccordionHandler({
      data,
      pageIndex,
      blockIndex,
      blockOrBlocks,
      spoilerIndex,
      spoilerBlockIndex,
      insertPosition,
    });

    if (!updatedPages) return;

    dispatch(updateLandingContentThunk({ pages: updatedPages }));
  };

export const removeLandingBlockInsideAccordionThunk =
  ({ pageIndex, blockIndex, spoilerIndex, spoilerBlockIndex }) =>
  async (dispatch, getStore) => {
    const data = itinerariesItemLandingSelector(getStore());

    const res = handlers.removeBlockInsideAccordionHandler({
      data,
      pageIndex,
      blockIndex,
      spoilerIndex,
      spoilerBlockIndex,
    });

    if (!res.updatedPages) return;

    if (res.showAlert) {
      confirmAlert({
        title: 'Are you sure you want to delete this element?',
        onConfirm: () => dispatch(updateLandingContentThunk({ pages: res.updatedPages })),
      });
    } else {
      dispatch(updateLandingContentThunk({ pages: res.updatedPages }));
    }
  };

export const editLandingBlockInsideAccordionThunk =
  ({ pageIndex, blockIndex, spoilerIndex, spoilerBlockIndex, newObject }) =>
  async (dispatch, getStore) => {
    const data = itinerariesItemLandingSelector(getStore());
    const updatedPages = handlers.editBlockInsideAccordionHandler({
      data,
      pageIndex,
      blockIndex,
      spoilerIndex,
      spoilerBlockIndex,
      newObject,
    });

    if (!updatedPages) return;

    dispatch(updateLandingContentThunk({ pages: updatedPages }));
  };
