import { Control, useForm } from 'react-hook-form';

import { AModal } from 'components/shared/AModal';
import Button from 'components/shared/Button';
import { HookCheckbox } from 'components/shared/HookFormComponents';
import Text from 'components/shared/Typography/Text';
import { useModalClose } from 'hooks/useModalClose';

import cs from '../index.module.css';

export const PrimaryImageWarning = ({
  onClose,
  onSave,
}: {
  onClose: () => void;
  onSave: (args: { isIgnoreForProposal: boolean }) => Promise<void>;
}) => {
  const { control, getValues } = useForm({ defaultValues: { isIgnoreForProposal: false } });
  // TODO: rework control props for adapters & delete it
  const depricatedControl = control as Control<any>;
  useModalClose();

  const handleSave = async () => {
    await onSave({ ...getValues() });
    onClose();
  };

  return (
    <AModal
      isOpen
      size="xs"
      onClose={onClose}
      header="Heads Up!"
      contentClassName={cs.modalContent}
      footer={
        <>
          <Button type="primary" size="medium" onClick={handleSave}>
            Okay, got it
          </Button>
        </>
      }
    >
      <form className={cs.description}>
        <Text type="accent">
          You've modified the itinerary. Remember to check that the primary photos you had previously selected are still
          relevant.
        </Text>

        <div className={cs.checkbox}>
          <HookCheckbox control={depricatedControl} name="isIgnoreForProposal">
            Don't show this again for this itinerary
          </HookCheckbox>
        </div>
      </form>
    </AModal>
  );
};
