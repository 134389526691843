import React from 'react';

import { SNACKBAR_SKIN } from 'components/shared/Snackbar';
import UpdateVersionDialog from 'components/shared/UpdateVersionDialog';
import { showSnackbar } from 'hooks/useSnackbar';
import { DOMAINS, getDomain, isGPCustomDomain } from 'utils/domain';
import { checkIsWebview } from 'utils/mobileApplication';

import { checkSWAlone } from '../serviceWorkerRegister';
import { inIframe } from './browserDetect';

export async function invokeServiceWorkerUpdateFlow(registration) {
  const domain = getDomain();
  const isInsideIframe = inIframe(); // check when react rendered inside SSR

  const isNeedToUpdateAutomatically =
    isInsideIframe ||
    checkIsWebview() ||
    (domain !== DOMAINS.ACCOUNT && domain !== DOMAINS.GUEST && !isGPCustomDomain());

  if (registration.waiting && isNeedToUpdateAutomatically) {
    registration.waiting.postMessage({ type: 'SKIP_WAITING' });

    return;
  }

  const handleClick = () => {
    if (registration.waiting) {
      // let waiting Service Worker know it should become active
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    }
  };

  const withSaveWarning = domain === DOMAINS.ACCOUNT;

  const isAlone = await checkSWAlone();

  if (!isAlone && !isInsideIframe) {
    showSnackbar({
      text: <UpdateVersionDialog withSaveWarning={withSaveWarning} onClick={handleClick} />,
      type: SNACKBAR_SKIN.WARNING,
      autoClose: false,
      uniqueId: 'snackbar__versionDivergence',
      fitContent: true,
    });
  }
}
