import React, { FC } from 'react';

import classes from 'classnames';
import { prop } from 'ramda';

import { ISwitcherProps } from '../types';
import styles from './index.module.css';

const Switcher: FC<ISwitcherProps> = ({ children, isVertical, size, onChange, isSolid, name, value }) => {
  const radioGroupClasses = classes(
    styles.radioGroup,
    isVertical && prop('radioGroup-vertical', styles),
    isSolid && prop('radioGroup-solid', styles),
  );

  const filteredChildren = React.Children.toArray(children).filter((c) => !!c);

  return (
    <div className={radioGroupClasses}>
      {React.Children.map(filteredChildren, (child: any, index) =>
        React.cloneElement(child, {
          size,
          isSolid,
          name,
          isFirst: index === 0,
          isLast: index === filteredChildren.length - 1,
          onClick: () => onChange && onChange(child.props.value),
          checked: child.props.value === value,
        }),
      )}
    </div>
  );
};

export default Switcher;
