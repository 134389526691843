import { createSelector } from '@reduxjs/toolkit';
import { isEmpty, isNil } from 'ramda';

import { EDetailsMode } from 'components/Pages/Admin/Itinerary/Details/types';
import { markEmptyPages } from 'components/Pages/Admin/shared/ItinerariesItemLanding/utils';
import { ITINERARIES_ITEM_TYPES } from 'constants/index';
import { EPageRole } from 'detailsv2/LandingV2/Page/types';
import { getLangingPageType } from 'utils/itineraries/functions';
import { isSchedulesEmpty } from 'utils/itineraries/functions/builderUtils';

export const itinerariesItemDataSelector = (state) => state.itinerariesItem;
export const itinerariesItemLocalVersionSelector = (state) => state.itinerariesItem?.localVersion;
export const itinerariesItemIdSelector = (state) => state.itinerariesItem?.id;
export const itinerariesItemLandingSelector = (state) => state.itinerariesItem?.landing;

export const itineraryAccountGtmContainerSelector = (state) => state.itinerariesItem?.account?.gtmContainerId;

export const itinerariesItemSettingsSelector = (state) => state.itinerariesItem?.settings;
export const itineraryCostsSelector = (state) => state.itinerariesItem?.costs;
export const itineraryLandingCurrentPageSelector = (state, pageIndex) =>
  state.itinerariesItem?.landing?.pages[pageIndex];
export const itineraryWaySelector = (state) => state.itinerariesItem?.way || [];
export const itinerarySchedulesSelector = (state) => state.itinerariesItem?.schedules || [];
export const itineraryScheduleTypeSelector = (state) => state.itinerariesItem?.scheduleType;
export const itineraryLandingPagesSelector = (state) => state.itinerariesItem?.landing?.pages || [];
export const itineraryDetailsModeSelector = (state) => {
  // TODO detailsMode key is also placed inside GP object, can be removed "IF" statement (check first)
  if (state.itinerariesItem.type === ITINERARIES_ITEM_TYPES.GUEST_PORTAL) {
    return state.itinerariesItem?.itinerary.detailsMode;
  }

  return state.itinerariesItem?.detailsMode;
};
export const itineraryActivePrimaryImagesSelector = (state, landingPageType) => {
  const pages = itineraryLandingPagesSelector(state);
  const currentSuggestId = pages?.find((page) => getLangingPageType(page) === landingPageType)?.primaryImageId;
  const primaryImageIds = pages
    ?.filter((page) => {
      const isDefaultPage = page && !isNil(page.primaryImageId) && page?.primaryImageId !== currentSuggestId;
      return isDefaultPage;
    })
    .map((page) => page?.primaryImageId);
  return primaryImageIds;
};

export const itineraryCurrentPageHiddenSelector = (state, pageIndex) => {
  const currentPage = itineraryLandingCurrentPageSelector(state, pageIndex);
  return currentPage?.hidden || false;
};

export const itineraryCurrentPageEmptySelector = (state, pageIndex) => {
  const currentPage = itineraryMarkEmptyPagesSelector(state)?.[pageIndex];
  return currentPage?.empty || false;
};

export const itineraryMarkEmptyPagesSelector = createSelector(
  itinerariesItemDataSelector,
  itinerarySchedulesSelector,
  itineraryWaySelector,
  itineraryLandingPagesSelector,
  (proposal, schedules, way, pages) => {
    const isSafariOverviewEmpty =
      proposal.detailsMode === EDetailsMode.Original ? isSchedulesEmpty(schedules) : isEmpty(proposal.tripEvents);
    const isSafariOverviewMapEmpty = way?.length === 0;

    const excludeRoles = []
      .concat(isSafariOverviewEmpty ? [EPageRole.SAFARI_OVERVIEW, EPageRole.TRAVEL_BRIEF] : [])
      .concat(isSafariOverviewMapEmpty ? [EPageRole.SAFARI_MAP] : []);

    return markEmptyPages({ pages, excludeRoles });
  },
);

export const itineraryWithoutEmptyPagesSelector = createSelector(
  itinerariesItemDataSelector,
  itineraryMarkEmptyPagesSelector,
  (itinerariesItem, pages) => {
    return { ...itinerariesItem, landing: { pages } };
  },
);

export const itinerariesItemStylesSelector = createSelector(itinerariesItemDataSelector, (page) => {
  return page?.customStyles || '';
});

export const itinerariesItemFontsSelector = createSelector(itinerariesItemDataSelector, (page) => {
  return page?.customFonts || [];
});

export const itinerariesItemAppSelector = (state) => state.itinerariesItem.app;
