import { createSlice } from '@reduxjs/toolkit';

import generateRandomString from 'utils/generateRandomString';

import { getFlightStatus } from '../../components/Pages/Admin/Itinerary/Details/DetailsChronologicalMode/utils';
import { ETripEvent } from '../../detailsv2/structure';
import { reverseFlightDateValues } from '../../utils/common';
import { isIDL } from '../../utils/date';
import { reducers } from './chronologicalDetails';

const initialState = {
  localVersion: 0, // used to track page saves
};

export const isInternalItem = (item) => typeof item?.id === 'string';
export const createRelocationsItem = () => {
  return {
    id: generateRandomString(8),
  };
};

const slice = createSlice({
  name: 'itinerariesItem',
  initialState,
  reducers: {
    setItinerariesItemDataAction: (state, action) => {
      // TODO refactoring
      return {
        ...action.payload,
        ...(action.payload.tripEvents && {
          tripEvents: action.payload.tripEvents.map((t) => {
            if (t.type === ETripEvent.Flight) {
              if (t.data.fromFsService) {
                t = { ...t, data: { ...t.data, flightStatus: getFlightStatus(t.data) } };
              }
              if (isIDL(t.datesRange.start, t.datesRange.end)) {
                return reverseFlightDateValues(t, true);
              }
            }
            return t;
          }),
        }),
        droppedTripEvents: [],
        localVersion: state.localVersion + 1,
      };
    },
    resetItinerariesItemDataAction: () => initialState,
    setLandingDataAction: (state, action) => {
      state.landing = action.payload;
    },
    setRelocationsDataAction: (state, action) => {
      state.relocations = action.payload;
    },
    createRelocationsItemAction: (state) => {
      state.relocations = [...state.relocations, createRelocationsItem()];
    },
    removeRelocationsItemAction: (state, action) => {
      state.relocations = state.relocations.map((item) =>
        item?.id === action.payload ? { ...item, _destroy: true } : item,
      );
    },
    removeAllRelocationsAction: (state) => {
      state.relocations = state.relocations.map((item) => ({ ...item, _destroy: true }));
    },
    initializRelocationsAction: (state, { payload }) => {
      state.relocations = payload;
    },
    setPolicyShareReportToFalseAction: (state) => {
      state.policies.shareReport = false;
    },
    ...reducers,
  },
});

export const {
  setItinerariesItemDataAction,
  resetItinerariesItemDataAction,
  setLandingDataAction,
  setRelocationsDataAction,
  createRelocationsItemAction,
  removeRelocationsItemAction,
  removeAllRelocationsAction,
  initializRelocationsAction,
  setPolicyShareReportToFalseAction,
  mergeTripEventsAction,
  addMergeItinerariesDataAction,
  createTripEventAction,
  updateTripEventAction,
  duplicateTripEventsAction,
  deleteTripEventAction,
  deleteTripEventsAction,
  insertDayAboveAction,
  setTripEventsAction,
  setOrganizedByAction,
  updateStartDateAction,
  updateTripEventsPositionsAction,
  setCollapsedDaysAction,
  updateEventDaysRangeAction,
  extendEventDaysRangeAction,
  highlightDaysAction,
} = slice.actions;

export default slice.reducer;
