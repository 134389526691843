import { FC } from 'react';

import classNames from 'classnames';
import { prop } from 'ramda';

import BaseRadioButton from 'components/shared/FormAdapters/FormBase/RadioButton';
import Group from 'components/shared/FormAdapters/FormDefault/RadioButton/Group';

import styles from './index.module.css';
import { IRadioButtonProps } from './types';

const RadioButton: FC<IRadioButtonProps> = ({
  className,
  noMargin,
  disabled,
  size,
  children,
  isError,
  checked,
  isSolid,
  isFirst,
  isLast,
  radioClassNames,
  type = 'default',
  ...rest
}) => {
  const radioWrapperClasses = classNames(
    styles.radioWrapper,
    type === 'primary' && styles.primary,
    noMargin && prop('radioWrapper-noMargin', styles),
    checked && prop('radioWrapper-active', styles),
    isError && prop('radioWrapper-error', styles),
    disabled && prop('radioWrapper-disabled', styles),
    size && prop(`radioWrapper-${size}`, styles),
    isSolid && prop('radioWrapper-solid', styles),
    className,
  );

  const radioClasses = classNames(
    styles.radio,
    checked && prop('radio-active', styles),
    disabled && prop('radio-disabled', styles),
    radioClassNames,
  );

  if (isSolid) {
    return (
      <label className={radioWrapperClasses}>
        <BaseRadioButton {...rest} className={styles.hiddenBaseRadionButton} disabled={disabled} checked={checked} />
        <div
          className={classNames(
            styles.radioTitleWrapper,
            isFirst && prop('radioTitleWrapper-first', styles),
            isLast && prop('radioTitleWrapper-last', styles),
          )}
        >
          <span className={styles.radioTitle}>{children}</span>
        </div>
      </label>
    );
  }

  return (
    <label className={radioWrapperClasses}>
      <BaseRadioButton {...rest} className={styles.hiddenBaseRadionButton} disabled={disabled} checked={checked} />
      {type === 'default' && <span className={radioClasses} />}
      <span className={styles.radioTitle}>{children}</span>
    </label>
  );
};

// RadioButton.Group = Group;
export { Group };
export default RadioButton;
