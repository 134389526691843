import { CSSProperties, forwardRef, LegacyRef, useState } from 'react';

import Observer from '@researchgate/react-intersection-observer';
import cx from 'classnames';

import { checkIsWebview } from 'utils/mobileApplication';

import Text from '../Typography/Text';
import {
  ASSET_RATIO,
  DIMENSIONS_STRATEGIES,
  DIMENSIONS_STRATEGY_TO_ITEM_STYLES_MAP,
  DIMENSIONS_STRATEGY_TO_CONTAINER_STYLES_MAP,
} from './constants';
import styles from './index.module.css';
import { IImageLoadingHandlerProps } from './types';

const ImageLoadingHandler = forwardRef(
  (
    {
      src,
      alt,
      onLoad,
      allowSave = false,
      isShowError = true,
      imageClassName,
      itemClassName,
      containerStyle,
      dimensionsStrategy = DIMENSIONS_STRATEGIES.ADAPTIVE_HEIGHT,
      imageStyles,
      placeholderStyles,
      ratio = ASSET_RATIO,
      noLazyLoading = false,
      pictureSources,
      defaultSrc,
    }: IImageLoadingHandlerProps,
    ref: LegacyRef<HTMLImageElement>,
  ) => {
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const noLazyLoadingFlag = checkIsWebview() ? true : noLazyLoading;
    const [isInViewport, setIsInViewport] = useState<boolean>(noLazyLoadingFlag);
    const [isError, setIsError] = useState<boolean>(false);
    let pictureErrorCount = 0;

    const handleImgLoaded = () => {
      setIsLoaded(true);

      if (onLoad) onLoad();
    };

    const imageLoadingStyles: CSSProperties = isLoaded
      ? {}
      : {
          visibility: 'hidden',
          opacity: 0,
          position: 'absolute',
        };

    const itemStyles = {
      ...DIMENSIONS_STRATEGY_TO_ITEM_STYLES_MAP[dimensionsStrategy],
    };
    const containerStyles = {
      ...DIMENSIONS_STRATEGY_TO_CONTAINER_STYLES_MAP[dimensionsStrategy],
    };

    const handleIntersection = (event: IntersectionObserverEntry, unobserve: () => void) => {
      if (event.isIntersecting) {
        setIsInViewport(true);
        unobserve();
      }
    };

    const handleError = () => {
      setIsError(true);
    };

    /*@ts-ignore*/
    const handlePictureError = (e) => {
      if (pictureErrorCount > 0) return setIsError(true);

      e.target.onerror = null;
      pictureSources.forEach((_, i) => {
        e.target.parentNode.children[i].srcset = src;
      });
      pictureErrorCount = 1;
    };

    return (
      <div
        className={cx(styles.container, !allowSave && styles.containerWithoutSave, 'custom-imagehandler-loading')}
        style={{ position: 'relative', ...containerStyle, ...containerStyles }}
      >
        {!isLoaded && (
          <Observer disabled={noLazyLoadingFlag} onChange={handleIntersection}>
            <div
              className={cx(styles.item, styles.placeholder, itemClassName, 'custom-imagehandler-item')}
              style={{ ...itemStyles, ...placeholderStyles }}
            >
              {isError && isShowError ? (
                <Text className={styles.errorMessage}>
                  Failed
                  <br />
                  to load
                </Text>
              ) : (
                <div className={styles.placeholderIconWrapper}>
                  <PlaceholderIcon defaultSrc={defaultSrc} />
                </div>
              )}
            </div>
          </Observer>
        )}

        {isInViewport &&
          (pictureSources ? (
            <>
              <picture>
                {pictureSources?.map((source, i) => <source key={i} media={source.media} srcSet={source.srcSet} />)}

                <img
                  ref={ref}
                  src={src}
                  alt={alt}
                  onLoad={handleImgLoaded}
                  onError={handlePictureError}
                  className={cx(styles.item, styles.image, imageClassName, itemClassName, 'custom-imagehandler-item')}
                  style={{ ...imageLoadingStyles, ...itemStyles, ...imageStyles }}
                  draggable={allowSave}
                  crossOrigin=""
                />
              </picture>
            </>
          ) : (
            <img
              ref={ref}
              src={src}
              alt={alt}
              onLoad={handleImgLoaded}
              onError={handleError}
              className={cx(styles.item, styles.image, imageClassName, itemClassName, 'custom-imagehandler-item')}
              style={{ ...imageLoadingStyles, ...itemStyles, ...imageStyles }}
              draggable={allowSave}
              crossOrigin=""
            />
          ))}

        {dimensionsStrategy === DIMENSIONS_STRATEGIES.ADAPTIVE_HEIGHT && (
          <div style={{ paddingBottom: (ratio[1] / ratio[0]) * 100 + '%' }} />
        )}
      </div>
    );
  },
);
/*@ts-ignore*/
ImageLoadingHandler.DIMENSIONS_STRATEGIES = DIMENSIONS_STRATEGIES;

export default ImageLoadingHandler;

function PlaceholderIcon({ defaultSrc }: { defaultSrc: string }) {
  if (defaultSrc) {
    return <img src={defaultSrc} alt={defaultSrc} className={styles.defaultSrc} />;
  }

  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_713_1314)">
        <path
          d="M37.125 6.875H6.875C6.14565 6.875 5.44618 7.16473 4.93046 7.68046C4.41473 8.19618 4.125 8.89565 4.125 9.625V34.375C4.125 35.1043 4.41473 35.8038 4.93046 36.3195C5.44618 36.8353 6.14565 37.125 6.875 37.125H37.125C37.8543 37.125 38.5538 36.8353 39.0695 36.3195C39.5853 35.8038 39.875 35.1043 39.875 34.375V9.625C39.875 8.89565 39.5853 8.19618 39.0695 7.68046C38.5538 7.16473 37.8543 6.875 37.125 6.875ZM26.8125 15.125C27.2204 15.125 27.6192 15.246 27.9584 15.4726C28.2975 15.6992 28.5619 16.0213 28.718 16.3982C28.8741 16.7751 28.915 17.1898 28.8354 17.5899C28.7558 17.99 28.5594 18.3575 28.2709 18.6459C27.9825 18.9344 27.615 19.1308 27.2149 19.2104C26.8148 19.29 26.4001 19.2491 26.0232 19.093C25.6463 18.9369 25.3242 18.6725 25.0976 18.3334C24.871 17.9942 24.75 17.5954 24.75 17.1875C24.75 16.6405 24.9673 16.1159 25.3541 15.7291C25.7409 15.3423 26.2655 15.125 26.8125 15.125ZM37.125 34.375H6.875V27.6186L14.8397 19.6522C14.9674 19.5243 15.119 19.4229 15.286 19.3537C15.4529 19.2845 15.6318 19.2489 15.8125 19.2489C15.9932 19.2489 16.1721 19.2845 16.339 19.3537C16.506 19.4229 16.6576 19.5243 16.7853 19.6522L28.3594 31.2228C28.6174 31.4808 28.9673 31.6258 29.3322 31.6258C29.6971 31.6258 30.047 31.4808 30.305 31.2228C30.563 30.9648 30.708 30.6149 30.708 30.25C30.708 29.8851 30.563 29.5352 30.305 29.2772L27.2697 26.2436L29.7344 23.7772C29.9922 23.5195 30.3418 23.3748 30.7063 23.3748C31.0708 23.3748 31.4204 23.5195 31.6783 23.7772L37.125 29.2308V34.375Z"
          fill="#3F3C43"
        />
      </g>
      <defs>
        <clipPath id="clip0_713_1314">
          <rect width="44" height="44" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
