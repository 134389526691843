import { useRef } from 'react';

import { useDispatch } from 'react-redux';

import { loginAsThunk } from 'store/auth/thunks';
import { getAccessToken, getRefreshToken, setToken } from 'utils/accessToken';
import { setAuthToken, setLoginAsHeader } from 'utils/axios';
import { deleteCookie, getCookie } from 'utils/cookies';
import { getLoginAsUserId } from 'utils/loginAs';

function TokenMiddleware({ children }) {
  const dispatch = useDispatch();
  const beforeMountRef = useRef(false);

  const accessToken = getCookie('access_token') ?? getAccessToken();
  const refreshToken = getCookie('refresh_token') ?? getRefreshToken();

  deleteCookie('access_token');
  deleteCookie('refresh_token');

  if (accessToken && refreshToken) setToken({ accessToken, refreshToken });

  if (!beforeMountRef.current) {
    setAuthToken(accessToken);

    const loginAsUserId = getLoginAsUserId();
    if (loginAsUserId) {
      setLoginAsHeader(loginAsUserId);
      dispatch(loginAsThunk(loginAsUserId.userId, loginAsUserId.accId));
    }

    beforeMountRef.current = true;
  }

  return children;
}

export default TokenMiddleware;
