import React, { ComponentProps, ReactNode } from 'react';

import classes from 'classnames';
import { prop } from 'ramda';

import SimpleDiv from 'components/shared/SimpleDiv';

import styles from './index.module.css';

interface ITextProps extends ComponentProps<'span'> {
  className?: string;
  children?: ReactNode | string;
  type?: string;
  isStrong?: boolean;
  isDisabled?: boolean;
  size?: string;
  accent?: boolean;
  hoefler?: boolean;
  italic?: boolean;
  marginBottom?: any;
  weight?: '400' | '500' | '600';
  color?: 'gray-500' | 'gray-600';
}

const Text: React.FC<ITextProps> = ({
  className,
  children,
  type,
  isStrong,
  isDisabled,
  size,
  accent,
  hoefler,
  italic,
  weight,
  marginBottom,
  color,
  ...rest
}) => {
  const textClasses = classes(
    styles.text,
    type && prop(`text-${type}`, styles),
    size && prop(`text-${size}`, styles),
    color && prop(`text-color-${color}`, styles),
    weight && prop(`text-font-weight-${weight}`, styles),
    accent && prop('text-accent', styles),
    hoefler && prop('text-hoefler', styles),
    isDisabled && prop('text-disabled', styles),
    marginBottom && prop('marginBottom', styles),
    italic && prop('italic', styles),
    className,
    'custom-typography-text',
    type && `custom-typography-text-type-${type}`,
    size && `custom-typography-text-size-${size}`,
    isDisabled && 'custom-typography-text-disabled',
  );

  if (isStrong) {
    return (
      <strong className={textClasses} {...rest}>
        {children}
        <SimpleDiv />
      </strong>
    );
  }

  return (
    <span className={textClasses} {...rest}>
      {children}
    </span>
  );
};

export default Text;
