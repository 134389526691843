import { createContext } from 'react';

import safari_portal from 'components/shared/images/safari_portal.svg';
import { LANDING_THEMES } from 'utils/itineraries/functions';

import { ILandingPageContext } from './types';

export const defaultValue = {
  // data props
  landingData: {}, // landing page
  landingPageType: null,
  page: {}, // page, itinerary, lookbook, guestportal
  links: [],
  // access props
  readOnly: true,
  hasHeader: true,
  isItinerariesItem: false,
  itinerariesItemType: null,
  landingTheme: LANDING_THEMES.default,
  headerOptions: {
    userName: 'Guest',
    logoSrc: safari_portal,
    logoCustomStyle: null,
    icons: [
      {
        type: 'share',
        onClick: () => {},
      },
      {
        type: 'print',
        onClick: () => {},
      },
      {
        type: 'phone',
        onClick: () => {},
      },
      // {
      //   type: 'help',
      //   onClick: () => {},
      // },
    ],
    iconsParams: {
      margin: 'normal',
    },
    userMenuActions: [],
  },
  hasPoweredBy: false,
  hasNavNotch: false,
  isPortal: false,
  isPortalItinerary: false,
  isInsideBuilder: false,
  // handlers
  onChangePageTitle: () => {},
  onReplaceCover: () => {},
  onUpdateBlock: () => {},
  onAddBlock: () => {},
  onAddBlockInsideSpoiler: () => {},
  onUpdateBlockInsideSpoiler: () => {},
  onDeleteBlockInsideSpoiler: () => {},
  onDeleteBlock: () => {},
  onChangeLeftSideText: () => {},
};

// FIXME: remove any
const LandingPageContext = createContext<ILandingPageContext>(defaultValue as any);

export default LandingPageContext;
