import React, { createContext, useContext, useEffect, useRef, useState } from 'react';

import { showModal } from 'modal.react.js';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import createCable from 'api/cable';
import { UserActivityChannel } from 'channels/UserActivityChannel';
import { TimelineBrokenModal } from 'components/Pages/Admin/shared/Modals/TimelineBrokenModal';
import { BackButtonContent } from 'components/shared/BackButtonContent';
import { LIBRARY_CONTENT, LIBRARY_ITINERARIES } from 'constants/index';
import { ITINERARIES_ITEM_TYPES } from 'constants/index';
import { CONTENT_LIBRARY_PATH } from 'constants/routes';
import { useAccountPath } from 'hooks/useAccountPath';
// eslint-disable-next-line import/named
import { indexOf, isEmpty, last } from 'ramda';
import { isLoginAsSelector } from 'store/auth/selectors';
import { highlightDaysAction } from 'store/itinerariesItem';
import { itinerariesItemDataSelector } from 'store/itinerariesItem/selectors';
import { userSelector } from 'store/user/selectors';
import { getProposalSteps } from 'utils/itineraries/map';
import { mapLibType2LibPath } from 'utils/map';

import ConfirmChangesModal from '../../components/Pages/Admin/shared/Modals/ConfirmChangesModal';
import area from '../../components/shared/GlobalModalArea/area';
import { useIgnoreWarning } from './functions';
import { validateTimeline } from './functions/builderUtils';

const getNextStep = (steps, currentStep) => {
  const currentStepIdx = indexOf(currentStep, steps);

  if (currentStepIdx === -1) {
    return null;
  }

  return steps[currentStepIdx + 1] || null;
};

const mapLibType2BackButtonText = (libType, proposal) => {
  switch (libType) {
    case LIBRARY_ITINERARIES:
      return <BackButtonContent title={proposal?.tourRequest?.fileName} subtitle={proposal.title} />;
    case LIBRARY_CONTENT: {
      const subtitle = proposal?.type === ITINERARIES_ITEM_TYPES.LOOKBOOK ? 'Lookbook Template' : 'Itinerary Template';
      return <BackButtonContent title={proposal.title} subtitle={subtitle} />;
    }
    default:
      return 'Back';
  }
};

export function ItinerariesItemProvider({ children, itemId, step, itemType }) {
  const { libType } = useParams();
  const dispatch = useDispatch();
  const proposal = useSelector(itinerariesItemDataSelector);
  const libURL = mapLibType2LibPath(libType);
  const getPath = useAccountPath();
  const steps = getProposalSteps(itemType, proposal);
  const isCreating = itemId === '0';
  const currentUserId = useSelector(userSelector).id;
  const [activeUsers, setActiveUsers] = useState([]);
  const [showChangesDetectedModal, setShowChangesDetectedModal] = useState(null);
  const [userConnected, setUserConnected] = useState(false);
  const isOverriding = useSelector(isLoginAsSelector);
  const { getIgnoredEvents, checkIsEventsWarningIgnored, ignoreWarning } = useIgnoreWarning();

  const handleChangesDetectedModal = (value) => setShowChangesDetectedModal(value);

  const chanRef = useRef(null);

  const withMultipleUsersWarning =
    (saveFn) =>
    async (...args) => {
      let response;
      sessionStorage.setItem('hideConfirmChangesForCurrentTab', 'true');
      try {
        if (activeUsers.length > 1) {
          await showModal(area, (res) => (
            <ConfirmChangesModal
              onClose={() => res(false)}
              onSave={async () => {
                response = await saveFn(...args);
              }}
            />
          ));
        } else {
          response = await saveFn(...args);
        }
        return response;
      } catch (e) {
        sessionStorage.removeItem('hideConfirmChangesForCurrentTab');
      }
    };

  const withTimelineBrokenWarning =
    (saveFn) =>
    async (...args) => {
      const ignoredEvents = getIgnoredEvents();
      const [invalidDays, invalidEventsByDates] = validateTimeline(
        proposal?.tripEvents,
        proposal?.scheduleType,
        ignoredEvents,
      );
      const isEventsIgnored = checkIsEventsWarningIgnored(invalidEventsByDates);
      if (!isEventsIgnored && !isEmpty(invalidDays)) {
        try {
          await showModal(area, (res) => (
            <TimelineBrokenModal
              onClose={() => res(false)}
              onHighlight={() => {
                dispatch(highlightDaysAction(invalidDays));
                setTimeout(() => dispatch(highlightDaysAction([])), 3000); // keep timeout amount in sync with css highlight animation
                res(false);
              }}
              onSave={async (values) => {
                res(values);
                const response = await saveFn(...args);

                if (response && values.isIgnoreForProposal) {
                  const [_, invalidEventsByDates] = validateTimeline(
                    response?.tripEvents,
                    proposal?.scheduleType,
                    ignoredEvents,
                  );
                  ignoreWarning(invalidEventsByDates);
                }
              }}
            />
          ));
        } catch (e) {
          return;
        }
      } else {
        await saveFn(...args);
      }
    };

  useEffect(() => {
    if (isOverriding) {
      return;
    }

    const cable = createCable();
    const channel = new UserActivityChannel(
      { [`${itemType}_id`]: itemId },
      {
        currentUserId,
        setActiveUsers,
        setShowChangesDetectedModal,
        setUserConnected,
      },
    );

    cable.subscribe(channel);

    channel.sendJoin({ tab: step });

    channel.setKeepAliveInterval();

    chanRef.current = channel;

    return () => {
      if (channel.state === 'connected') {
        channel.disconnect();
        cable.disconnect();
      }
      channel.removeKeepAliveInterval();
    };
  }, [isOverriding]);

  useEffect(() => {
    chanRef?.current?.sendChangeTab({ tab: step });
  }, [step]);

  /**
   * @type {ItinerariesItemContext}
   */
  const data = {
    backButton: {
      link: getPath(libURL),
      text: mapLibType2BackButtonText(libType, proposal),
      isSSR: getPath(libURL) === getPath(CONTENT_LIBRARY_PATH),
    },
    steps,
    currentStep: step,
    nextStep: getNextStep(steps, step),
    lastStep: last(steps),
    itemId,
    isCreating,
    itemType,
    activeUsers,
    userConnected,
    showChangesDetectedModal,
    handleChangesDetectedModal,
    withMultipleUsersWarning,
    withTimelineBrokenWarning,
  };

  return <ItinerariesItemContext.Provider value={data}>{children}</ItinerariesItemContext.Provider>;
}

const ItinerariesItemContext = createContext(null);

/**
 * Itineraries item context data
 * @typedef {object} ItinerariesItemContext
 * @property {object} backButton data of the back button
 * @property {string} backButton.link link of back button
 * @property {string} backButton.text text of back button
 * @property {string[]} steps available steps for current item
 * @property {string} currentStep current step
 * @property {string} nextStep next step
 * @property {string} lastStep last step
 * @property {string} itemId item id
 * @property {boolean} isCreating is item in create mode?
 * @property {string} itemType itineraries item type
 * @property {object[]} activeUsers active users list
 * @property {boolean} userConnected is user connected?
 * @property {boolean} showChangesDetectedModal show Changes Detected modal
 * @property {function} handleChangesDetectedModal
 * @property {function} withMultipleUsersWarning
 * @property {function} withTimelineBrokenWarning
 */

/**
 * @returns {ItinerariesItemContext}
 */
export const useItinerariesItemContext = () => useContext(ItinerariesItemContext);
