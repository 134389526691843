import { pick, pipe } from 'ramda';

import { getEventDuration } from 'components/Pages/Admin/Itinerary/Details/DetailsChronologicalMode/utils';
import { recalculateRanges } from 'components/Pages/Admin/Itinerary/Details/utils';
import { EOrganizedBy, ETripEvent } from 'detailsv2/structure';
import { addDaysToDate, getCurrentDateString } from 'utils/date';
import { isDefined, isNotDefined } from 'utils/ramda';

import {
  deleteTripEvent,
  getNewTripEvent,
  insertDayAbove,
  syncTripEvents,
  updateEventDaysRange,
  extendEventDaysRange,
  updateStartDate,
  updateTripEvent,
  updateTripEventsPositions,
} from './utils';

export const reducers = {
  setOrganizedByAction: (state, { payload }) => {
    if (payload === EOrganizedBy.Date) {
      const allTripEventsWithoutDate = state.tripEvents.every((t) => !isDefined(t.datesRange.start));

      if (allTripEventsWithoutDate) {
        state.tripEvents = state.tripEvents.map((t) => {
          const curDate = getCurrentDateString();
          const eventDuration = getEventDuration(t);
          const startDate = addDaysToDate(curDate, t.daysRange.start - 1);

          const getEndDate = () => {
            if (eventDuration > 1) {
              return addDaysToDate(startDate, eventDuration - 1);
            }
            // These event types have separate inputs for startDate and endDate
            return [ETripEvent.Area, ETripEvent.Flight, ETripEvent.Transfer].includes(t.type) ? startDate : null;
          };

          return {
            ...t,
            datesRange: {
              start: startDate,
              end: getEndDate(),
            },
          };
        });
      }
    }

    state.scheduleType = payload;
  },
  setTripEventsAction: (state, { payload }) => {
    state.tripEvents = payload.tripEvents;
  },
  setCollapsedDaysAction: (state, { payload }) => {
    state.collapsedDays = payload;
  },
  highlightDaysAction: (state, { payload }) => {
    state.highlightedDays = payload;
  },
  createTripEventAction: (state, { payload }) => {
    const tripEvent = getNewTripEvent(state.tripEvents, payload, state.scheduleType);

    state.tripEvents = syncTripEvents(state.scheduleType, [...state.tripEvents, tripEvent]);
  },

  /**
   * Merge trip events from template into current trip events.
   * Events will be recalculated to match the target schedule type.
   
  * Should be used with `addMergeItinerariesDataAction`
  
   * @param {object} payload - object with the following properties:
   * - `tripEvents`: array of trip events to be merged
   * - `targetOrganizedBy`: the organized by value of the target item
   * - `sourceOrganizedBy`: the organized by value of the source item
   * - `referenceDate`: null or date in the format 'yyyy-MM-dd' which is used to calculate dates of the merged events items
   * - `referenceDay`: null or number which is used to calculate days of the merged events items
   */
  mergeTripEventsAction: (state, { payload }) => {
    const tripEventsFromTemplate = payload.tripEvents.map(({ id: _id, ...event }) => ({ ...event }));
    const recalculateTripEvents = recalculateRanges.recalculateRangesForChronological({
      itemsArray: tripEventsFromTemplate,
      targetOrganizedBy: payload.targetOrganizedBy,
      sourceOrganizedBy: payload.sourceOrganizedBy,
      referenceDate: payload.referenceDate,
      referenceDay: payload.referenceDay,
    });

    const tripEvents = recalculateTripEvents
      .sort((a, b) => a.positions[0] - b.positions[0])
      .reduce(
        (acc, event) => {
          const newEvent = getNewTripEvent(acc, event, state.scheduleType);
          return acc.concat(newEvent);
        },
        [...state.tripEvents],
      );

    state.tripEvents = syncTripEvents(state.scheduleType, [...tripEvents]);
  },

  /**
   * Adds mergeItineraries for correct merging of days image on backend side.
   * This action is used after successful merge of itineraries.
   * @param {object} payload - object with the following properties:
   * - `mergeItineraryId`: id of the merged itinerary
   * - `referenceDay`: day number which is used as a reference for the merged itinerary
   */
  addMergeItinerariesDataAction: (state, { payload }) => {
    const { mergeItineraryId: id, referenceDay: startDay } = payload;
    state.mergeItineraries = [...(state.mergeItineraries || []), { id, startDay }];
  },
  updateTripEventAction: (state, { payload }) => {
    state.tripEvents = updateTripEvent(payload, state.tripEvents, state.scheduleType);
  },
  updateEventDaysRangeAction: (state, { payload }) => {
    state.tripEvents = updateEventDaysRange(payload, state.tripEvents);
  },
  extendEventDaysRangeAction: (state, { payload }) => {
    state.tripEvents = extendEventDaysRange(payload, state.tripEvents, state.scheduleType);
  },
  insertDayAboveAction: (state, { payload }) => {
    state.tripEvents = insertDayAbove(state.tripEvents, payload.day);
  },
  duplicateTripEventsAction: (state, { payload }) => {
    const { ids } = payload;

    if (isNotDefined(ids)) return;

    const events = state.tripEvents.filter((event) => ids.includes(event.id));
    const newEvents = events.map((event) => ({
      ...pick(['type', 'data', 'daysRange', 'datesRange', 'attachedPages', 'icon'], event),
      positions: [0],
    }));

    newEvents.forEach((event) => {
      reducers.createTripEventAction(state, { payload: event });
    });
  },
  deleteTripEventAction: (state, { payload }) => {
    const { id } = payload;

    const { tripEvents, droppedTripEvents } = deleteTripEvent(id, state.tripEvents, state.droppedTripEvents);

    state.tripEvents = tripEvents;
    state.droppedTripEvents = droppedTripEvents;
  },
  deleteTripEventsAction: (state, { payload }) => {
    const { ids } = payload;

    if (isNotDefined(ids)) return;

    // sequentially use deleteTripEvent for each id
    const { tripEvents, droppedTripEvents } = pipe(
      ...ids.map(
        (id) =>
          ({ tripEvents, droppedTripEvents }) =>
            deleteTripEvent(id, tripEvents, droppedTripEvents),
      ),
    )({ tripEvents: state.tripEvents, droppedTripEvents: state.droppedTripEvents });

    state.tripEvents = tripEvents;
    state.droppedTripEvents = droppedTripEvents;
  },
  updateStartDateAction: (state, { payload }) => {
    state.tripEvents = updateStartDate(state.tripEvents, payload);
  },
  updateTripEventsPositionsAction: (state, { payload }) => {
    state.tripEvents = updateTripEventsPositions(state.tripEvents, payload);
  },
};
