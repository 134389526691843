import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: undefined,
  useCustomPrivacyPolicy: false,
};

// reducer for app current user
const slice = createSlice({
  name: 'privacyPolicy',
  initialState,
  reducers: {
    setPrivacyPolicyAction: (state, { payload }) => {
      state.data = payload?.privacyPolicy;
      state.useCustomPrivacyPolicy = payload?.useCustomPrivacyPolicy;
    },
  },
});

export const { setPrivacyPolicyAction } = slice.actions;

export default slice.reducer;
