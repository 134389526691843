import React, { useCallback, useEffect, useRef, useState } from 'react';

import cx from 'classnames';
import { useSelector } from 'react-redux';

import ImageLoadingHandler from 'components/shared/ImageLoadingHandler';
import { useRect } from 'hooks/useRect';
import { accountIdSelector, isSuperAdminSelector } from 'store/user/selectors';

function calculateShift(dimensionRatio, containerSize, imageSize, focus) {
  const containerCenter = containerSize / 2;
  const scaledImage = imageSize / dimensionRatio;
  const scaledFocus = scaledImage * focus;

  if (scaledFocus > scaledImage - containerCenter) {
    return (scaledImage - containerSize) * -1;
  }

  if (scaledFocus < containerCenter) {
    return 0;
  }

  return (scaledFocus - containerCenter) * -1;
}

const DEFAULT_IMAGE_STYLES = {
  position: 'absolute',
  left: 0,
  top: 0,
  minHeight: '100%',
  minWidth: '100%',
};

const CONTAINER_STYLES = {
  position: 'relative',
  height: '100%',
  width: '100%',
  overflow: 'hidden',
};

function getImageStyles(img, container, point) {
  if (point?.length !== 2) {
    return { height: '100%', width: '100%', objectFit: 'cover' };
  }

  if (img && container) {
    const { naturalHeight, naturalWidth } = img;
    const { height, width } = container.getBoundingClientRect();

    if (!naturalHeight || !naturalWidth || !height || !width) {
      return { display: 'none' };
    }

    const style = {
      ...DEFAULT_IMAGE_STYLES,
    };

    const widthRatio = naturalWidth / width;
    const heightRatio = naturalHeight / height;

    if (widthRatio > heightRatio) {
      style.maxHeight = '100%';
      style.maxWidth = `${naturalWidth / heightRatio}px`;
      style.left = calculateShift(heightRatio, width, naturalWidth, point[0] / 100);
    } else {
      style.maxWidth = '100%';
      style.maxHeight = 'initial';
      style.top = calculateShift(widthRatio, height, naturalHeight, point[1] / 100);
    }

    return style;
  }
}

export default function FocalImage({
  src,
  point,
  component = 'img',
  fileAccId,
  noLazyLoading,
  pictureSources,
  defaultSrc,
  className,
  isShowError,
  ...rest
}) {
  const container = useRef();
  const { height } = useRect(container);
  const img = useRef();
  const [, setIter] = useState(0);
  const accountId = useSelector(accountIdSelector);
  const isSuperAdmin = useSelector(isSuperAdminSelector);
  const showContextMenu = isSuperAdmin || accountId === fileAccId;
  const Component = component;

  const onLoad = useCallback(() => {
    setIter((it) => it + 1);
  }, []);

  useEffect(() => {
    setIter((i) => i + 1);
  }, []);

  useEffect(() => {
    const fn = () => setIter((i) => i + 1);
    window.addEventListener('resize', fn);

    return () => {
      window.removeEventListener('resize', fn);
    };
  }, []);

  const imageStyles = getImageStyles(img.current, container.current, point);

  useEffect(() => {
    setIter((i) => i + 1);
  }, [height]);

  if (component === 'img') {
    return (
      <div ref={container} style={CONTAINER_STYLES} {...rest} className={cx(className, 'ImageLoadingHandler')}>
        <ImageLoadingHandler
          ref={img}
          src={src}
          defaultSrc={defaultSrc}
          imageStyles={imageStyles}
          placeholderStyles={{ width: '100%', height: '100%' }}
          containerStyle={{ position: 'static' }}
          alt="img"
          onLoad={onLoad}
          isShowError={isShowError}
          allowSave={showContextMenu}
          dimensionsStrategy={ImageLoadingHandler.DIMENSIONS_STRATEGIES.CONTROLLABLE}
          noLazyLoading={noLazyLoading}
          pictureSources={pictureSources}
        />
      </div>
    );
  }

  return (
    <div ref={container} style={CONTAINER_STYLES} {...rest}>
      <Component ref={img} style={getImageStyles(img.current, container.current, point)} src={src} onLoad={onLoad} />
    </div>
  );
}
