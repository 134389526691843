import {
  MOBILE_OPERATING_SYSTEMS,
  TRAVEL_PORTAL_APPLE_APP_STORE_IDENTIFIER,
  TRAVEL_PORTAL_APP_PACKAGE_IDENTIFIER,
  TRAVEL_PORTAL_APP_SCHEMA,
  VIEWED_MOBILE_APP_INFO,
} from 'constants/mobileApplication';
import { TApp } from 'utils/itineraryDetails/types';

export const getMobileOperatingSystem = () => {
  // @ts-ignore
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/android/i.test(userAgent)) {
    return MOBILE_OPERATING_SYSTEMS.android;
  }

  // @ts-ignore
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return MOBILE_OPERATING_SYSTEMS.ios;
  }

  return null;
};

export const checkIsWebview = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const webviewParam = urlParams.get('webview');

  return webviewParam === 'true';
};

export const checkIsWebviewReady = () => {
  // @ts-ignore
  if (window.ReactNativeWebView) {
    // @ts-ignore
    window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'page is ready', payload: null }));
  }
};

export const checkIsViewedPath = (path: string): boolean => {
  const viewedProposalsStr = localStorage.getItem(VIEWED_MOBILE_APP_INFO);

  if (!viewedProposalsStr) {
    return false;
  }

  const viewedProposalsArr = JSON.parse(viewedProposalsStr);

  return viewedProposalsArr.includes(path);
};

export const markPathAsViewed = (path: string, paths: string[] = []) => {
  const viewedProposalsStr = localStorage.getItem(VIEWED_MOBILE_APP_INFO);

  if (!viewedProposalsStr) {
    localStorage.setItem(VIEWED_MOBILE_APP_INFO, JSON.stringify([path, ...paths.filter((p) => p !== path)]));
    return;
  }

  const viewedProposalsArr = JSON.parse(viewedProposalsStr);

  if (!viewedProposalsArr.includes(path)) {
    localStorage.setItem(VIEWED_MOBILE_APP_INFO, JSON.stringify([...viewedProposalsArr, path]));
  }

  paths.forEach((p) => {
    if (!viewedProposalsArr.includes(p)) {
      localStorage.setItem(VIEWED_MOBILE_APP_INFO, JSON.stringify([...viewedProposalsArr, p]));
    }
  });
};

export const checkIsViewedByRedirectUri = (uri: string): boolean => {
  const parts = uri.split('/');
  const id = parts[parts.length - 1];
  const viewedProposalsStr = localStorage.getItem(VIEWED_MOBILE_APP_INFO);

  if (viewedProposalsStr && id) {
    return viewedProposalsStr.includes(id);
  }

  return false;
};

export const getStoreUrl = (app?: TApp) => {
  const OS = getMobileOperatingSystem();

  if (OS === MOBILE_OPERATING_SYSTEMS.ios) {
    const bundleID = app?.appleAppStoreIdentifier ?? TRAVEL_PORTAL_APPLE_APP_STORE_IDENTIFIER;
    return `https://apps.apple.com/app/${bundleID}`;
  }

  if (OS === MOBILE_OPERATING_SYSTEMS.android) {
    const packageIdentifier = app?.androidPackageIdentifier ?? TRAVEL_PORTAL_APP_PACKAGE_IDENTIFIER;
    return `https://play.google.com/store/apps/details?id=${packageIdentifier}`;
  }
};

export const getDeeplinkUrl = (app?: TApp, path?: string) => {
  const appSchema = app?.schema ?? TRAVEL_PORTAL_APP_SCHEMA;
  return `${appSchema}://${path}`;
};
