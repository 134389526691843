import React from 'react';

import cx from 'classnames';
import { easeQuadInOut } from 'd3-ease';
import { prop } from 'ramda';
import ReactDOM from 'react-dom';
import { Animate } from 'react-move';

import Icon from 'components/shared/Icon';
import Close from 'components/shared/Icon/icons/Close';

import styles from './index.module.css';
import { ModalNoPortal } from './ModalNoPortal';

/**
 * @deprecated - Use GlobalModalArea Instead
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<{}> & React.RefAttributes<unknown>>}
 */
const Modal = React.forwardRef((props, ref) => {
  // BASE SIZE PROPS - sm, md(base), lmd, lg, auto
  const {
    className,
    isOpen,
    children,
    duration = 300,
    toClose,
    noPadding = false,
    size,
    withoutCloseBtn,
    noPortal = false,
    contentClassName,
    isNewStyle = false,
    closeClassName,
  } = props;
  const modalClasses = cx(styles.modal, className, 'custom-modal');
  const modalContentClasses = cx(
    styles.content,
    noPadding && styles.contentNoPadding,
    size && prop(`content-${size}`, styles),
    contentClassName,
    'custom-modal-content',
  );

  const marginClasses = cx(
    styles.margin,
    size && prop(`margin-${size}`, styles),
    noPadding && prop('margin-noPadding', styles),
  );

  if (noPortal)
    return (
      <ModalNoPortal
        modalClasses={modalClasses}
        modalContentClasses={modalContentClasses}
        marginClasses={marginClasses}
        {...props}
      />
    );

  return ReactDOM.createPortal(
    <Animate
      show={isOpen}
      start={{ opacity: 0 }}
      enter={{
        opacity: [1],
        x: [0],
        timing: { duration, ease: easeQuadInOut },
      }}
      leave={{
        opacity: [0],
        x: [-110],
        timing: { duration, ease: easeQuadInOut },
      }}
    >
      {({ opacity }) => (
        <div className={cx(styles.modalWrap, isNewStyle && styles.modalWrapNewStyle)} ref={ref}>
          <div tabIndex={0} onClick={toClose} className={styles.modalBackdrop} style={{ opacity }} />
          <div className={marginClasses}>
            <div className={modalClasses} style={{ opacity }}>
              {isOpen && (
                <div className={modalContentClasses}>
                  {!withoutCloseBtn && (
                    <button
                      type="button"
                      className={cx(styles.modalClose, 'custom-modal-close-btn', closeClassName)}
                      tabIndex={0}
                      onClick={toClose}
                    >
                      <Icon component={Close} className={cx(styles.closeIcon, 'custom-modal-close-icon')} />
                    </button>
                  )}

                  {children}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </Animate>,
    document.body,
  );
});

export default Modal;
