import queryString from 'query-string';

import { defaultLandingPageTypes } from 'constants/index';
import { IGetUrl, ILoadSuggested } from 'utils/types';

import axios from './axios';
import { axiosGetAutoCancelDecorator } from './axiosGetAutoCancelDecorator';
import { ITINERARIES_ITEM_TYPE_TO_ROUTE_SEGMENT_MAP } from './itineraries/functions';

const getUrl = ({
  params,
  accountId,
  isItinerariesItem,
  itinerariesItemType,
  itinerariesItemId,
  landingPageType,
  activePrimaryImageIds,
}: IGetUrl) => {
  if (isItinerariesItem && landingPageType) {
    const isDefaultLandingPageType = defaultLandingPageTypes.includes(landingPageType);
    if (isDefaultLandingPageType) {
      const activePrimaryImageIdsQuery = queryString.stringify(
        { used_ids: activePrimaryImageIds },
        { arrayFormat: 'bracket' },
      );
      return `/acc/${accountId}/${ITINERARIES_ITEM_TYPE_TO_ROUTE_SEGMENT_MAP[itinerariesItemType]}/${itinerariesItemId}/suggested_primary?${activePrimaryImageIdsQuery}`;
    }
  }

  return `/acc/${accountId}/assets/suggested?${params}`;
};

const decoratedAxiosGet = axiosGetAutoCancelDecorator();

export async function loadSuggested({
  accountId,
  pageId,
  onlyPrimary,
  type = 'media',
  ownership = 'all',
  isRooms = false,
  isItinerariesItem = false,
  itinerariesItemType,
  itinerariesItemId,
  landingPageType,
  activePrimaryImageIds = [],
}: ILoadSuggested) {
  const params = queryString.stringify({
    page_id_rooms: isRooms ? pageId : undefined,
    page_id: isRooms ? undefined : pageId,
    only_primary: onlyPrimary,
    type,
    ownership,
    limit: 250,
    countless: '1',
  });

  const url = getUrl({
    accountId,
    isItinerariesItem,
    itinerariesItemType,
    itinerariesItemId,
    params,
    landingPageType,
    activePrimaryImageIds,
  });
  const res = await axios.get(url);

  const result = res?.data?.data;
  result.length = res?.data?.meta?.currentCount;

  return result;
}

export async function loadBySearch(
  accId: string,
  search: string,
  type: string = 'media',
  ownership: 'all' | 'private' | 'portal' = 'all',
) {
  const params = queryString.stringify({
    q: search,
    type,
    ownership,
    limit: 250,
    countless: '1',
  });

  const res = await decoratedAxiosGet(`/acc/${accId}/assets/suggested?${params}`);

  return res?.data?.data;
}
